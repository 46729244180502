import React from 'react';
import './ScopeofWork.css'; 

function ScopeofWork() {
  return (
    <div className="scope-container">
      <div className="heading">Scope of work</div>
      <div className="content">Paragraph Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ijfndsf dnfijfiw sdnfijdsfijs <br></br> alrihgt so the issiue is that we nare hnerver nkow anything about you so kindlky </div>
    </div>
  );
}

export default ScopeofWork;
